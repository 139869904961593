<template>
  <div id="member-transfer-approvel">
    <v-container class="py-0">
      <v-speed-dial
        v-if="FloatingButtonFlag"
        v-model="fab"
        :top="top"
        :bottom="bottom"
        :right="right"
        :left="left"
        :direction="direction"
        :open-on-hover="hover"
        :transition="transition"
        :fixed="true"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="blue darken-2" dark fab>
            <v-icon v-if="fab"> mdi-close </v-icon>
            <v-icon v-else> mdi-account-circle </v-icon>
          </v-btn>
        </template>
        <!-- <v-btn fab dark small color="green">
          <v-icon>mdi-pencil</v-icon>
        </v-btn> -->
        <v-btn
          fab
          dark
          small
          color="indigo"
          @click.prevent="addRecordPrompt = true"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn fab dark small color="red">
          <v-icon>mdi-delete</v-icon>
        </v-btn> -->
      </v-speed-dial>
    </v-container>
    <v-dialog v-model="previewRecordPrompt" persistent max-width="75%">
      <members-transfer-preview
        :pageTitle="previewRecordPageTitle"
        :previewRecordFlag="previewRecordFlag"
        :recordData="selectedData"
        @hidePreviewRecordPrompt="hidePreviewRecordPrompt"
        @hideEditRecordPrompt="hideEditRecordPrompt"
        v-if="previewRecordPrompt"
      ></members-transfer-preview>
    </v-dialog>
    <v-card>
      <v-card-title class="text-h5">
        <h2>
          {{ PageTitle }}
        </h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>
          {{ PageDescription }}
        </h4>
      </v-card-subtitle>

      <v-card-text>
        <v-container>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
        </v-container>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" sm="6" lg="3" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Year</h6>
                </label>
                <v-autocomplete
                  @change="getZoneCodeOptions"
                  :reduce="(option) => option.value"
                  v-model="JciYearCode"
                  :rules="JciYearCodeRules"
                  :items="JciYearCodeOptions"
                  :loading="JciYearCodeOptionsLoading"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>Zone <small>(optional)</small></h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>Lom <small>(optional)</small></h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  :loading="SearchFlag"
                  color="#8950FC"
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                >
                  Search
                </v-btn>
                <!-- <v-btn
                  v-if="
                    tableOptions1.ExcelDownloadFlag && tableData1.length > 0
                  "
                  color="info"
                  @click.prevent="
                    generateExcel(
                      tableData1,
                      tableOptions1.ExcelFields,
                      tableOptions1.ExcelFileName,
                      'My Worksheet'
                    )
                  "
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                  ><v-icon dark> mdi-download </v-icon> Download</v-btn
                > -->
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-container class="notes">
          <p>Important notes:</p>
          <ol>
            <li>
              The first tab shows the list of transfer request for your
              approval; Request of members willing to transfer from one LO to
              other LO.
            </li>
            <li>The second tab shows the pending / rejected transfer requests.</li>
            <li>The third tab shows the completed transfer requests.</li>
          </ol>
          <p>Status meaning:</p>
          <ol>
            <li>
              Pending for confirmation: The first LO president has initiated the
              request and the confirmation from the second LO President is
              pending.
            </li>
            <li>
              Pending for approval: Both the Presidents have confirmed the
              transfer request and the approval of NHQ is pending.
            </li>
            <li>
              Approved: The final approval is received from the NHQ and the
              member is transferred successfully.
            </li>
            <li>
              Not approved: The transfer request is not approved by the other LO
              President or the NHQ; Refer the approval description for reason.
            </li>
          </ol>
        </v-container>

        <br />

        <v-tabs
          v-model="tab"
          background-color="#8950FC"
          centered
          dark
          icons-and-text
          show-arrows
          v-if="
            tableData.length > 0 ||
            tableData1.length > 0 ||
            tableData2.length > 0
          "
        >
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1" :disabled="TabDisable1">
            Pending For Approval
            <v-icon>mdi-account-question</v-icon>
          </v-tab>

          <v-tab href="#tab-2" :disabled="TabDisable2">
            Pending / Rejected Transfer
            <v-icon>mdi-account-remove</v-icon>
          </v-tab>

          <v-tab href="#tab-3" :disabled="TabDisable3">
            Completed Transfer
            <v-icon>mdi-account-check</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          v-if="
            tableData.length > 0 ||
            tableData1.length > 0 ||
            tableData2.length > 0
          "
        >
          <v-tab-item value="tab-1">
            <v-card flat>
              <p></p>
              <v-container class="py-0" v-if="tableData.length == 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>No members found.</h4>
                  </v-col>
                </v-row>
              </v-container>

              <v-container class="py-0" v-if="tableData.length > 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3>{{ tableData.length }} members found</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      v-if="
                        tableOptions.ExcelDownloadFlag && tableData.length > 0
                      "
                      color="info"
                      @click.prevent="
                        generateExcel(
                          tableData,
                          tableOptions.ExcelFields,
                          tableOptions.ExcelFileName,
                          'My Worksheet'
                        )
                      "
                      class="btn btn-primary font-size-h6 px-6 py-4 my-3 mr-3 white--text"
                      ><v-icon dark> mdi-download </v-icon> Download</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Search records here"
                      single-line
                      hide-details
                    ></v-text-field>
                    <br />
                    <v-data-table
                      class="elevation-1"
                      v-model="selected"
                      :headers="tableColumns"
                      :items="tableData"
                      :search="search"
                      :show-select="tableOptions.ShowSelectFlag"
                      :item-key="tableOptions.ItemKey"
                      :single-select="tableOptions.SingleSelectFlag"
                      :items-per-page="tableOptions.ItemsPerPage"
                      :footer-props="tableOptions.FooterProps"
                    >
                      <template v-slot:item.ActiveStatusTxt="{ item }">
                        <v-chip
                          :color="getActiveStatusColor(item.ActiveStatusTxt)"
                          draggable
                          dark
                          >{{ item.StatusTxt }}</v-chip
                        >
                      </template>
                      <template v-slot:item.MemberImagePath="{ item }">
                        <img
                          width="100"
                          height="100"
                          :src="item.MemberImagePath"
                        />
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.PreviewFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="blue"
                              @click="previewData(item, 1, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-search
                            </v-icon>
                          </template>
                          <span> Preview </span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.EditFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="success"
                              @click="previewData(item, 2, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-shield-check
                            </v-icon>
                          </template>
                          <span> Approve </span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-2">
            <v-card flat>
              <p></p>
              <v-container class="py-0" v-if="tableData1.length == 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>No members found.</h4>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="py-0" v-if="tableData1.length > 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3>{{ tableData1.length }} members found</h3>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      v-if="
                        tableOptions1.ExcelDownloadFlag && tableData1.length > 0
                      "
                      color="info"
                      @click.prevent="
                        generateExcel(
                          tableData1,
                          tableOptions1.ExcelFields,
                          tableOptions1.ExcelFileName,
                          'My Worksheet'
                        )
                      "
                      class="btn btn-primary font-size-h6 px-6 py-4 my-3 mr-3 white--text"
                      ><v-icon dark> mdi-download </v-icon> Download</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row wrap v-if="tableData1.length > 0">
                  <v-col align="center" cols="12" md="12">
                    <v-text-field
                      v-model="search2"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <br />
                    <v-data-table
                      class="elevation-1"
                      v-model="selected1"
                      :headers="tableColumns1"
                      :items="tableData1"
                      :search="search1"
                      :show-select="tableOptions1.ShowSelectFlag"
                      :item-key="tableOptions1.ItemKey"
                      :single-select="tableOptions1.SingleSelectFlag"
                      :items-per-page="tableOptions1.ItemsPerPage"
                      :footer-props="tableOptions1.FooterProps"
                    >
                      <template v-slot:item.ActiveStatusTxt="{ item }">
                        <v-chip
                          :color="getActiveStatusColor(item.ActiveStatusTxt)"
                          draggable
                          dark
                          >{{ item.StatusTxt }}</v-chip
                        >
                      </template>
                      <template v-slot:item.ProfilePic="{ item }">
                        <img width="100" height="100" :src="item.ProfilePic" />
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.PreviewFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="blue"
                              @click="previewData(item, 1, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-search
                            </v-icon>
                          </template>
                          <span> Preview </span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.EditFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="primary"
                              @click="previewData(item, 2, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-document-edit
                            </v-icon>
                          </template>
                          <span> Edit </span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>

          <v-tab-item value="tab-3">
            <v-card flat>
              <p></p>
              <v-container class="py-0" v-if="tableData2.length == 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h4>No members found.</h4>
                  </v-col>
                </v-row>
              </v-container>
              <v-container class="py-0" v-if="tableData2.length > 0">
                <br />
                <v-row wrap>
                  <v-col align="center" cols="12" md="12">
                    <h3>{{ tableData2.length }} members found</h3>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      v-if="
                        tableOptions2.ExcelDownloadFlag && tableData2.length > 0
                      "
                      color="info"
                      @click.prevent="
                        generateExcel(
                          tableData2,
                          tableOptions2.ExcelFields,
                          tableOptions2.ExcelFileName,
                          'My Worksheet'
                        )
                      "
                      class="btn btn-primary font-size-h6 px-6 py-4 my-3 mr-3 white--text"
                      ><v-icon dark> mdi-download </v-icon> Download</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row wrap v-if="tableData2.length > 0">
                  <v-col align="center" cols="12" md="12">
                    <v-text-field
                      v-model="search2"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                    <br />
                    <v-data-table
                      class="elevation-1"
                      v-model="selected2"
                      :headers="tableColumns2"
                      :items="tableData2"
                      :search="search2"
                      :show-select="tableOptions2.ShowSelectFlag"
                      :item-key="tableOptions2.ItemKey"
                      :single-select="tableOptions2.SingleSelectFlag"
                      :items-per-page="tableOptions2.ItemsPerPage"
                      :footer-props="tableOptions2.FooterProps"
                    >
                      <template v-slot:item.ActiveStatusTxt="{ item }">
                        <v-chip
                          :color="getActiveStatusColor(item.ActiveStatusTxt)"
                          draggable
                          dark
                          >{{ item.StatusTxt }}</v-chip
                        >
                      </template>
                      <template v-slot:item.ProfilePic="{ item }">
                        <img width="100" height="100" :src="item.ProfilePic" />
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-tooltip bottom v-if="item.PreviewFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="blue"
                              @click="previewData(item, 1, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-search
                            </v-icon>
                          </template>
                          <span> Preview </span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.EditFlag">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              icon
                              size="25px"
                              color="primary"
                              @click="previewData(item, 2, e)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-file-document-edit
                            </v-icon>
                          </template>
                          <span> Edit </span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <v-row
          wrap
          v-if="
            tableData.length == 0 &&
            tableData1.length == 0 &&
            tableData2.length == 0
          "
        >
          <v-col align="center" cols="12" md="12">
            <h4>No members found.</h4>
          </v-col>
        </v-row>

        <br /><br />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import MembersTransferPreview from "@/view/pages/erp/member-transfer/MembersTransferPreview.vue";

export default {
  mixins: [common],
  components: {
    MembersTransferPreview,
  },
  data() {
    return {
      valid1: true,
      LoadingFlag: false,
      SearchFlag: false,
      StartupLoadingFlag: false,
      FloatingButtonFlag: false,

      previewRecordPrompt: false,
      previewRecordPageTitle: "",

      $selectedData: {},

      tab: null,

      tableColumns: [],
      tableOptions: [],
      tableData: [],
      selected: [],
      search: "",

      tableColumns1: [],
      tableOptions1: [],
      tableData1: [],
      selected1: [],
      search1: "",

      tableColumns2: [],
      tableOptions2: [],
      tableData2: [],
      selected2: [],
      search2: "",

      TabDisable1: false,
      TabDisable2: false,
      TabDisable3: false,

      CurrentYearId: 0,

      zone: {},
      lom: {},

      JciYearCodeRules: [(v) => !!v || "Year is required"],
      JciYearCode: "",
      JciYearCodeOptions: [],
      JciYearCodeOptionsLoading: false,

      ZoneCodeRules: [],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      ApprovalDescription: "",
      ApprovalDescriptionRules: [],

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var flag = tr.PagePermissionFlag;
        console.log("PagePermissionFlag=" + flag);

        if (!flag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;

        this.getYearCodeOptions();
      }
    },
    JciYearCodeOptions: function () {
      console.log("watch JciYearCodeOptions");
      this.LoadingFlag = false;
      this.JciYearCodeOptionsLoading = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
  },
  methods: {
    // code 1
    validate() {
      if (this.$refs.form1.validate()) {
        this.snackbar = true;
      }
    },
    resetForm() {
      this.search = "";
      this.rows = [];
      this.selected = [];
      this.$refs.form3.reset();
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "members_transfer",
        Action: "list_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    getYearCodeOptions() {
      console.log("getYearCodeOptions called");
      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      // var CurrentYearId = this.CurrentYearId;
      var CurrentYearId = this.YearCode;
      console.log("CurrentYearId=" + CurrentYearId);
      this.LoadingFlag = true;
      var selectbox1_source = "JciYearCode";
      var selectbox1_destination = "JciYearCodeOptions";
      var selectbox1_url = "api/jci-year/options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      var yearCode = this.JciYearCode;
      console.log("yearCode=" + yearCode);
      if (yearCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-lom/zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          YearCode: yearCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (yearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      console.log("YearCode=" + YearCode + ", ZoneCode=" + ZoneCode);
      if (YearCode != "" && ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 1,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        this.toast("error", message);
      }
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        this.getApprovalMembersList();
        this.getTransferStatusList();
        this.getAllTransferList();
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields";
        }
        this.toast("error", message);
      }
    },
    getApprovalMembersList() {
      console.log("getApprovalMembersList is called");

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/transfer/list";
      var upload = {
        UserInterface: 3,
        YearCode: this.JciYearCode,
        ZoneCode: this.ZoneCode,
        LomCode: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions = options;

          if (flag == 1) {
            thisIns.tableColumns = records.TableHeader;
            thisIns.tableData = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;
          thisIns.tableData = [];
        });
    },
    getTransferStatusList() {
      console.log("getTransferStatusList is called");

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData1 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/transfer/list";
      var upload = {
        UserInterface: 1,
        YearCode: this.JciYearCode,
        ZoneCode: this.ZoneCode,
        LomCode: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions1 = options;

          if (flag == 1) {
            thisIns.tableColumns1 = records.TableHeader;
            thisIns.tableData1 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;
          thisIns.tableData1 = [];
        });
    },
    getAllTransferList() {
      console.log("getAllTransferList is called");

      this.LoadingFlag = true;
      this.SearchFlag = true;
      this.tableData2 = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/transfer/list";
      var upload = {
        UserInterface: 4,
        YearCode: this.JciYearCode,
        ZoneCode: this.ZoneCode,
        LomCode: this.LomCode,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          var options = records.TableOptions;
          console.log({ options });
          thisIns.tableOptions2 = options;

          if (flag == 1) {
            thisIns.tableColumns2 = records.TableHeader;
            thisIns.tableData2 = records.TableData;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.LoadingFlag = false;
          thisIns.SearchFlag = false;
          thisIns.tableData1 = [];
        });
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    previewData(tr, type) {
      console.log("previewData called");
      console.log("type=" + type);
      // var tr = this.selected
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log("tr=" + JSON.stringify(tr));
      // n1 = 0;
      if (n1 > 0) {
        this.previewRecordPrompt = true;
        this.previewRecordFlag = type == 1 ? true : false;
        this.previewRecordPageTitle =
          type == 1 ? "Member Transfer Preview" : "Approve Member Transfer";
        this.selectedData = tr;
      } else {
        var message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.previewRecordPrompt = false;
      this.searchForm();
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#member-transfer-approvel {
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
  .notes {
    color: black;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
  }
}
</style>